/*************************************************************

ToggleSwitch

*************************************************************/

.ToggleSwitch {
  display:  flex;
  flex-direction:  row;
  align-items: center;
}

.toggle-switch-label {
  margin-right:  10px;
  font-size:  0.9em;
  color:  #A99E96;
}


.toggle-switch-button {
  position: relative;
  display: flex;
  width: 46px;
  height: 26px;
  margin:  0;
}

/* Hide default HTML checkbox */
.toggle-switch-button input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.toggle-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000033;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 25px;
  box-shadow: 0px 0px 0 1px rgba(255,255,255,0.15);
}

.toggle-switch-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: #ffffffdd;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
  
}

input:checked + .toggle-switch-slider {
  background-color: var(--green-color);
  box-shadow: 0px 0px 0 1px rgba(255,255,255,0) !important;
}

input:checked + .toggle-switch-slider:before {
  background-color: #ffffff;
}


.toggle-switch-slider:hover {
  box-shadow: 0px 0px 0 1px rgba(255,255,255,0.5);
}
.toggle-switch-slider:hover:before {
  background-color: #ffffff;
}

input:checked + .toggle-switch-slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}