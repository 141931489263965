.Contacts {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contact-list-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.contact-list-header {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  margin: 10px;
}

.contact-list-search {
  flex-grow: 1;
  width: 100%;
}

.contact-list-search-input input {
  background:  url('../../assets/img/icons/search.svg') var(--color-very-light-grey);
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 21px;
  padding-left: 40px !important;
}

.contact-list {
  margin-bottom: 10px;
}

.contact-list-item {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 0 10px;
  align-items: center;
  border-radius: 3px;
  position: relative;
  border-bottom: solid 1px var(--color-light-grey);
}

.contact-list-item {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 0 10px;
  align-items: center;
  border-radius: 3px;
  position: relative;
  border-bottom: solid 1px var(--color-light-grey);
}

.contact-list-item:active {
  transform: scale(0.98);
}

.contact-list-item-photo {
  display:  flex;
  justify-content: center;
  margin-right: 10px;
}

.contact-list-item-photo .photo-img {
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 50%;
}

.contact-list-item-photo .photo-img.placeholder {
  border-radius: 0%;
}
.contact-list-item-body {
  display: flex;
  flex-direction: column;
}
.contact-list-item-title {
  width: 100%;
  display:  flex;
  flex-direction: row;
  justify-content: flex-start;
}

.contact-list-item-title .strong {
  font-family: Rubik-Regular;
}

.contact-list-item:hover {
  background: #F7EEFF;
  cursor: pointer;
}


.contact-buttons {
	margin-top: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.contact-button {
	display:  flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 17vw;
}

.contact-button-icon .icon {
	width: 44px;
	height: 44px;
	background-size: 50px;
	margin: 0;
}

.contact-button-label {
	font-size: 0.8em;
	margin-top: 5px;
	color:  var(--color-dark-grey);
}

.contact-button:hover .contact-button-label {
	color: var(--color-black);
}

.contact-button:active .contact-button-icon .icon {
  transform: scale(0.9);
}

.contact-button:hover .icon-phone .circle,
.contact-button:hover .icon-sms .circle {
	fill:  var(--color-green) !important;
	stroke: var(--color-green) !important;;
}

.contact-button:hover .icon-email .circle {
	fill:  var(--color-purple) !important;
	stroke: var(--color-purple) !important;;
}

.contact-button:hover .icon-teams .circle,
.contact-button:hover .icon-linkedin .circle {
	fill:  var(--color-blue) !important;
	stroke: var(--color-blue) !important;;
}

.contact-button:hover .icon .content {
	fill: #fff;
}

.contact-content {
	background: var(--color-very-light-grey);
}
.contact-card {
	border-right: 1px solid var(--color-light-grey);
	max-width: 400px;
	padding-bottom: 20px !important;
}

.contact-card .ObjectDetails {
	max-width: 400px;
	padding-bottom: 0;
}

.contact-card .ObjectDetails .object-title,
.contact-card .ObjectDetails .object-title h1,
.contact-card .ObjectDetails .object-content,
.contact-card .ObjectDetails .Photo {
	transition: none !important;
	transform: none !important;
}



.contact-card .button-back {
  position: absolute;
  z-index: 10;
  top: 7px;
  left: 10px;
  border: 0px solid var(--color-very-light-grey);
  border-radius: 50px;
  background:  none;
  height: 35px;
  padding: 5px 13px;
  margin: 0 20px 0px 0;
  text-transform: uppercase;
  font-size:  0.8em;
  cursor: pointer;
  display:  flex;
  flex-direction: row;
  align-items: center;
  font-family: "Rubik-Light";
  transition: background 0.1s ease-out, color 0.1s ease-out, border-color 0.1s ease-out, right 0.2s ease-out;
}
.contact-card .button-back:hover {
  border: 0px solid var(--color-black);
  background:  var(--color-black);
  color:  #fff;
}
.contact-card .button-back:active {
  transform:  scale(0.95);
}
.contact-card .button-back .button-back-arrow {
  transform: rotate(-45deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 10px;
  height: 10px; 
  margin: 0 3px 0 0;
}

.contact-card .button-back .button-back-arrow::after,
.contact-card .button-back .button-back-arrow::before {
  content: '';
  background: var(--color-black);
  position: absolute;
  border-radius: 2px;
  left: 0px;
  top: 0px;
  transition: background 0.1s ease-out;
}
.contact-card .button-back .button-back-arrow::after {
  width: 10px;
  height: 2px;
}
.contact-card .button-back .button-back-arrow::before {
  width: 2px;
  height: 10px;
}
.contact-card .button-back:hover .button-back-arrow::after,
.contact-card .button-back:hover .button-back-arrow::before {
  background: #fff;
}
.contact-card .button-back .button-back-label {
  overflow: hidden;
  text-align: left;
  transition: opacity 0.2s ease-out;
}


@media (max-aspect-ratio: 1/1) {

	.contact-list-container {
	  width: initial;
	  margin-left: initial;
	}

	.main-content .columns.profile {
		align-items: stretch;
		justify-content: center !important;
	}

	.contact-content .large-nav-links-title {
		display:  flex;
	}
	.contact-card {
		border-right:  none;
		align-items: center;
		max-width: initial;
	}
	.contact-content {
		align-self: stretch;
	}
	.contact-info-container {
		align-self: stretch;
	}
}