.Questionnaire {
	position: relative;
	margin-bottom: 30px;
}

.questionnaire-title {
	font-size:  1.5em;
	font-family: Rubik-Medium;
	margin: 10px 0;
	display: flex;
	justify-content: space-between;
}

.questionnaire-description {
	margin: 0 0 20px 0;
}

.questionnaire-accept-conditions-container {
	display:  flex;
	margin:  40px 0 20px 0;
	cursor: pointer;
}
.questionnaire-accept-conditions-container:hover .CustomCheckbox input ~ .checkmark {
  border-color: var(--color-black);
}

.questionnaire-accept-conditions-container:active .CustomCheckbox .checkmark {
  transform: scale(0.8);
}
.questionnaire-accept-conditions-container.disabled {
	cursor: default;
}
.questionnaire-accept-conditions-container.disabled:hover .CustomCheckbox input ~ .checkmark {
  border-color: var(--color-medium-grey);
}

.questionnaire-accept-conditions-container.disabled:active .CustomCheckbox .checkmark {
  transform: none;
}

.questionnaire-accept-conditions {
	margin-left: 20px;
}

.questionnaire-footer {
	margin: 40px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.questionnaire-small-text {
	color:  var(--color-dark-grey);
	font-size: 0.9em;
	margin: 5px 0 10px 0;
}

.Questionnaire .close-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transform: rotate(45deg);
  position: absolute;
  top: 10px;
  right: 0;
  cursor: pointer;
}
.Questionnaire .close-button::after {
  width: 20px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-black);
  position: relative;
  left: 5px;
  top: 12px;
  display: block;
}
.Questionnaire .close-button::before {
  width: 20px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-black);
  position: relative;
  left: 5px;
  top: 14px;
  display: block;
  transform: rotate(90deg);
}
.Questionnaire .close-button:hover {
  background: var(--color-black);
}
.Questionnaire .close-button:hover::after {
  background: #fff;
}
.Questionnaire .close-button:hover::before {
  background: #fff;
}

.Questionnaire strong {
	font-family: Rubik-Regular;
	font-weight: normal;
}

.questionnaire-response-header {
	margin-bottom: 30px;
}

.questionnaire-response-header-row {
	color: var(--color-dark-grey);
	font-size: 0.9em;
	margin-bottom: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.questionnaire-response-header-label {
	min-width: 100px;
}

.questionnaire-response-header .Photo,
.questionnaire-response-header .photo-img {
	width: 24px !important;
	height: 24px !important;
}
.questionnaire-response-header .Photo {
	margin: 0 10px 0 0;
}

.questionnaire-sent {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	margin-top: 10vh;
}

.questionnaire-sent-text {
	font-size: 1.5em;
	margin: 10px 0 20px 0;
}

.questionnaire-sent-smalltext {
	font-size: 0.9em;
	color: var(--color-dark-grey);
	margin: 10px 0 20px 0;
}

.questionnaire-sent-icon .icon {
	width: 120px;
	height: 120px;
}
.questionnaire-sent-icon path {
	fill: none !important;
	stroke: url(#icon-give-feedback-gradient-green) !important;
}

.questionnaire-sent-icon .fill {
	fill: none !important;
	stroke: none !important;
}

.questionnaire-loading {
	text-align: center;
	padding-top: 30vh;
}

.questionnaire-not-found {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	margin-top: 10vh;
}

.questionnaire-not-found-text {
	margin: 40px 0 60px 0;
}

.questionnaire-not-found-icon .icon {
	width: 120px;
	height: 120px;
}
.questionnaire-not-found-icon path {
	fill: url(#icon-not-found-gradient) !important;
}



.Question {
	padding: 15px 0;
}

.question-title {
	font-family: Rubik-Regular;
	margin: 0 0 10px 0;
}

.Question.section .question-title {
	border-top: 2px solid var(--color-light-grey);
	font-family: Rubik-Light;
	padding: 10px 0 0 0;
	margin: 20px 0 10px 0;
} 
.Question.section .question-description {
	color:  var(--color-black);
	font-size: 0.9em;

} 
.question-description {
	color:  var(--color-dark-grey);
	font-size: 0.9em;
	margin: -5px 0 10px 0;
	white-space: pre-wrap;
}

.question-response {
	margin: 10px 0 0 0;
}
.question-respondent {
	display: flex;
	align-items: center;
	margin: 0 0 0 0;
}
.question-respondent .Photo,
.question-respondent .photo-img {
	width: 24px !important;
	height: 24px !important;
}
.question-respondent-name {
	margin: 0 0 0 10px;
	color: var(--color-dark-grey);
	font-size: 0.9em;
	width: 100%;
}
.question-response-value {
	margin: 0 0 0 34px;
}

.chart-custom-tooltip {
  display:  flex;
  /*visibility: hidden;*/
  flex-direction: column;
  background:  #fff;
  /*border:  1px solid var(--color-dark-grey)66;*/
  border-radius: 3px;
  box-shadow: 1px 1px 2px 1px rgba(35,31,32,0.2);
  padding:  8px;
  font-size:  0.9em;
}

.chart-mean-label {
	font-family: Rubik-Regular;
}

.likert {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}
.likert-min-label,
.likert-max-label {
	font-size:  1.0em;
	color: var(--color-dark-grey);
}
.alternatives {
	display: flex;
}

.alternative {
}

.likert.large {
	display: flex;
	align-items: stretch;
	flex-direction: column-reverse;
	padding: 10px 0 0 0;
}

.likert.large .likert-labels {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 10px 0 10px;
	font-size: 0.9em;
}

.likert.large .alternatives {
	justify-content: space-between;
}

.alternative .label-top {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	margin: 0 10px;
}
.alternative .label-top .label {
	margin-bottom: 10px;
}

.CustomRadio {
	font-size: 0.75em !important;
	font-family: Rubik-Medium !important;
}

.CustomRadio {
	cursor: pointer;
}

.CustomRadio input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: var(--color-very-light-grey);
  /* Not removed via appearance */
  margin: 0;
	cursor: pointer;
  font: inherit;
  color: var(--color-black);
  width: 17px;
  height: 17px;
  border: 1px solid var(--color-light-grey);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CustomRadio:hover input[type="radio"],
.CustomRadio:active input[type="radio"],
.CustomRadio input[type="radio"]:checked {
	border: 1px solid var(--color-black) !important;
}

.CustomRadio:active input[type="radio"] {
	transform: scale(0.9);
}

.CustomRadio input[type="radio"]::before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  transform: scale(0);
  transition: 100ms transform ease-in-out;
  background: var(--color-black);
  /*box-shadow: inset 1em 1em var(--color-black);*/
}

.CustomRadio input[type="radio"]:checked::before {
  transform: scale(1);
}


.CustomRadio.label-center {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	margin: 0 10px;
	position: relative;
}
.CustomRadio.label-center .label {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

.CustomRadio.label-center {
	font-size: 0.9em !important;
	font-family: Rubik-Regular !important;
}

.CustomRadio.label-center input[type="radio"] {
	border: 1px solid var(--color-medium-grey);
  background-color: #fff;
}

.CustomRadio.label-center input[type="radio"],
.CustomRadio.label-center input[type="radio"]::before,
.CustomRadio.label-center .label {
  width: 45px;
  height: 45px;
}

.CustomRadio.label-center.checked .label {
  color: #fff;
}

.CustomRadio.disabled {
	cursor: default;
}

.CustomRadio.disabled:active input[type="radio"] {
	transform: none;
}

.CustomRadio.disabled:hover input[type="radio"] {
	background: initial;
}

.CustomRadio.disabled input[type="radio"] {
	border: 1px solid var(--color-medium-grey);
}
.CustomRadio.disabled .label {
	color: var(--color-dark-grey);
}

.CustomRadio.disabled:hover input[type="radio"] {
	border: 1px solid var(--color-medium-grey) !important;
}

.CustomRadio.disabled input[type="radio"]:checked::before {
	background: var(--color-medium-grey);
}

.CustomRadio.read-only input[type="radio"]:checked::before {
	background: var(--color-black) !important;
}
.CustomRadio.read-only:hover input[type="radio"] {
	border: 1px solid var(--color-medium-grey) !important;
}
.CustomRadio.read-only input[type="radio"]:checked {
	border: none !important;
}

@media (max-aspect-ratio: 1/1) {

	.likert.large .likert-labels {
		padding: 10px 0px 0 0px;
	}

	.alternative .label-top {
		margin: 0 0;
	}

	.CustomRadio.label-center {
	  margin: 0;
	}

	.CustomRadio.label-center input[type="radio"],
	.CustomRadio.label-center input[type="radio"]::before,
	.CustomRadio.label-center .label {
	  width: 35px;
	  height: 35px;
	}
}
