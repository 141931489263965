.icon-analyze-feedback,
.icon-give-feedback,
.icon-invite-feedback,
.icon-not-found {
  width: 24px;
  height: 30px;
}

.icon-analyze-feedback.icon path {
  stroke: none !important;
}