.Analyze .main-toolbar {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}
.Analyze .main-toolbar-header {
	display: flex;
	align-items: center;
	height: 40px;
}

.Analyze .main-toolbar-filters {
	height: 0;
	overflow: hidden;
	margin-bottom: 0;
	transition: height 0.2s ease-out, margin 0.2s ease-out;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	box-sizing: border-box;
	width: initial;
}

.Analyze .main-toolbar.show  {
	height: initial;
}

.Analyze .main-toolbar.show .main-toolbar-filters {
	height: 60px;
	margin-bottom: 20px;
}

.Analyze .main-toolbar-item {
	margin: 0 10px;
	box-sizing: border-box;
	min-width: initial !important;
}

.Analyze .selected-items-options {
	justify-self: flex-start;
}

.Analyze .main-toolbar-filter-toggle-button,
.Analyze .main-toolbar-filter-reset-button {
	display: flex;
	align-items: center;
	margin-left: auto;
	width: 35px;
	height: 35px;
	border: 1px solid var(--color-medium-grey);
	border-radius: 3px;
	cursor: pointer;
}

.Analyze .main-toolbar-filter-toggle-button:hover,
.Analyze .main-toolbar.show .main-toolbar-filter-toggle-button
 {
	border: 1px solid var(--color-black);
	background: var(--color-black);
}
.Analyze .main-toolbar-filter-toggle-button:hover .icon-filter path,
.Analyze .main-toolbar.show .main-toolbar-filter-toggle-button .icon-filter path {
	stroke: #fff;
	fill: #fff !important;
}
.Analyze .main-toolbar-filter-toggle-button:hover .icon-filter path:first-child {
	stroke: #222020 !important;
}

.Analyze .main-toolbar-filter-toggle-button:active {
	transform: scale(0.95);
}

.feedback .button .icon path {
  display: none;
}
.feedback .button .icon path.fill {
  display: initial;
}
.feedback .button:hover .icon path.fill {
  fill: #fff !important;
}

@media (max-aspect-ratio: 1/1) {

	.Analyze .main-toolbar-title {
		font-size: 1.0em;
		align-items: center;
		display: flex;
	}

	.Analyze .main-toolbar-filters {
		
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}

	.Analyze .main-toolbar.show .main-toolbar-filters {
		height: initial;
		margin-bottom: 0px;
	}

	.Analyze .main-toolbar-item {
		margin: 10px 0;
	}

}