
.profile-content {
	background: var(--color-very-light-grey);
}
.profile-card {
	border-right: 1px solid var(--color-light-grey);
	max-width: 400px;
	padding-bottom: 20px !important;
}

.profile-card .ObjectDetails {
	max-width: 400px;
	padding-bottom: 0;
}

.profile-card .ObjectDetails .Photo .error-notification {
  display: block;
}

.profile-card .ObjectDetails .object-title,
.profile-card .ObjectDetails .object-title h1,
.profile-card .ObjectDetails .object-content,
.profile-card .ObjectDetails .Photo {
	transition: none !important;
	transform: none !important;
}

.email-signature-container {
	display:  flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 400px;
}
.email-signature {
	background: #fff;
	padding: 20px;
	border-radius: 5px;
	align-self: stretch;
	box-shadow: 0px 2px 10px #22202011;
	border: 1px solid var(--color-light-grey);
	margin: 20px 0 10px 0;
	height: 250px;
	display: flex;
	align-items: center;
}

.email-signature-instructions {
	display:  flex;
	flex-direction: column;
	align-items: center;
	margin-top: 30px;
}

.email-signature-include-photo-button {
	width: 100%;
	text-align: right;
	padding: 0 10px 0 0;
	align-self: stretch;
}

.email-signature-include-photo-button .label {
	font-family: Rubik-Medium;
	font-size: 0.75em;
}

.email-signature-copy-button {
	margin: 0 0 10px 0;
}

.email-signature-instructions li {
	color: var(--color-dark-grey);
	margin: 10px 20px 10px 40px;
	font-size: 0.9em;
}

@media (max-aspect-ratio: 1/1) {

	.main-content .columns.profile {
		align-items: stretch;
		justify-content: center !important;
	}

	.profile-content .large-nav-links-title {
		display:  flex;
	}
	.profile-card {
		border-right:  none;
		border-bottom: 1px solid var(--color-light-grey);
		align-items: center;
		max-width: initial;
	}
	.profile-content {
		align-self: stretch;
	}
	.profile .large-nav-links {
		align-items: center;
		justify-content: center;
	}
	.email-signature-container {
		max-width: 400px;
		width: 100%;
	}
}